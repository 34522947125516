import { Product } from '@models/context/product';

/**
 * A config setting whether external links are allowed or not
 *
 * @param product The product
 * @returns Whether external links are allowed or not
 */
export const EXTERNAL_LINKS_CONFIG = (product: Product): boolean => {
  switch (product) {
    case Product.Show:
      return false;
    default:
      return true;
  }
};
